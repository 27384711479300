import React, { useEffect, useState, useContext } from "react"
import { graphql } from "gatsby"
import Layout from "../components/wrappers/layout"
import SEO from "../components/seo"
import CardArticle from "../components/card-article"
import SiteHeaderAlt from "../components/wrappers/site-header-alt"
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider"
import { domain, formatRichText, stripString } from "../utils"
import Markdown from "markdown-to-jsx"
import { FormattedMessage } from "react-intl";
import Media from "../components/media";
import Link from "../components/link";
import { getSinglePage } from "../utils";
import classNames from "classnames"
import Tags from "../components/tags"
import { Code, CustomHr, Div } from "../components/CustomHr"

const BlogDetail = ({ data }) => {
  const { blog, headers, footers, general } = data.strapi; 
  const { generalBackgroundColorDarkMode, generalBackgroundColorLightMode  , fontColorDarkMode, fontColorLightMode, hashtagsBackgroundColor, hashtagsBackgroundColorDarkMode,  clientLogosColorDarkMode ,clientLogosColorLightMode } = general; 

  const {
    title,
    headerImage,
    author,
    authorHeading,
    category,
    categoryLink,
    date,
    dateLabel,
    intro,
    main,
    articles,
    tags, 
    copyright
  } = blog
  const language = blog?.language
  const header = headers && headers.find((header)=>  header.language === language ); 
  const footer = footers && footers.find((footer)=>  footer.language === language ) || {}; 
  const imageBorderRadiusForServices = general?.imageBorderRadiusForServices; 
  const seoTitle = blog && blog.seo && blog.seo.metaData?.metaTitle || title  || "Blog detail ";
  const seoDescription = blog &&  blog.seo && blog.seo.metaData?.metaDescription || title||"blog detail";
  const seoKeywords = blog && blog.seo && blog.seo?.metaData?.keywords; 
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext); 
  const isSubOpen = state.siteSubNav;

  const allPages = data?.allSitePage?.nodes; 
  const webpages = blog && blog.webpages && blog.webpages || {}; 
  const redirectTo = getSinglePage(webpages, allPages); 
  const [anchorLinks, setAnchorLinks] = useState([])
  const newAnchorLinks = []; 
  const showX = {path: `/${language}/blog/`}; 
  const darkMode = state.darkModePreference === 'moon'; 
  const sectionBackgroundColor = darkMode ? generalBackgroundColorDarkMode : generalBackgroundColorLightMode; 
  const websiteFontColor = darkMode ? fontColorDarkMode : fontColorLightMode; 
  const filteredRelatedCards = articles?.relatedCard.filter(relatedCard => !relatedCard?.blog?.inactive || relatedCard?.case_study); 
  const logosBgColor = darkMode ? clientLogosColorDarkMode : clientLogosColorLightMode; 
  const displayedHashtagBgColor = darkMode ? hashtagsBackgroundColorDarkMode : hashtagsBackgroundColor;
  
  if (copyright) {
    footer.copyright = copyright;
  }

  useEffect(() => {
    dispatch({ type: "SET_SCROLL_TO_SECTION" , payload: {
      id: stripString(title)
    }});  

    main && main.forEach((component) => {
      if (component.__typename === "STRAPI_ComponentPlainRichTextBlock" || component.__typename === "STRAPI_ComponentPlainPaddedRichText") {
        component &&  component.anchorText && newAnchorLinks.push({header: component.anchorText , display: true}); 
      } 
    })

    setAnchorLinks(newAnchorLinks);

    const id = "blog"; 
    if (!isSubOpen[id]) {
      dispatch({ 
        type: "TOGGLE_SITE_SUB_NAV" , 
        payload: {
          id: id
        } 
      });
    } 

    return () => {
      dispatch({ 
        type: "RESET_SCROLL_TO_SECTION", 
      });
    }
  }, []); 
  
  return (
    <Layout header={header} footer={footer} language={language} redirectTo={redirectTo} anchorLinks={anchorLinks} showX={showX} logosBgColor={logosBgColor}>
      <SEO
        websiteFontColor={websiteFontColor} 
        template="blog-detail" 
        title={seoTitle} 
        description={seoDescription} 
        redirectTo={redirectTo} 
        keywords={seoKeywords} 
        darkTheme={blog.darkTheme}
        darkMode={darkMode} 
        websiteBackgroundColor={sectionBackgroundColor}
      />
      <SiteHeaderAlt header={header} anchorLinks={anchorLinks} showX={showX}>
        <article className="blog section" style={{backgroundColor: sectionBackgroundColor}}>
          <header className="header container">
            <div className="row">
              <div className="offset-md-1 col-md-8">
                <Tags 
                  tags={tags.filter((tag) => tag?.active)} 
                  showLargeMargin={true} 
                  hashtagsBackgroundColor={displayedHashtagBgColor}
                  borderRadius={imageBorderRadiusForServices}
                />
                <h1 className="header__heading header__heading--space">
                  {title}
                </h1>
              </div>
            </div>
          </header>

          <div className="blog-detail__hero">
            <img
              className="image--fluid"
              src={`${headerImage && headerImage.url}`}
              alt="alt"
            />
          </div>
          <div className="blog-detail__content">
            <div className={[
              "container",
              "blog-detail__header",
              intro ? "" : "blog-detail__header--nointro"
            ].join(" ")}>
              <div className="row">
                <div className="col-md-3 offset-md-1">
                  <div className={`blog-detail__meta d-flex  ${categoryLink ? "" : "blog-detail__meta--authorwithdate"}`}>
                    <author className="blog-detail__author">
                      <div className="blog-detail__author-avatar">
                        <img
                          className="image--fluid"
                          src={`${author && author.picture && author.picture.url}`}
                          style={{borderRadius: imageBorderRadiusForServices}}
                          alt={author && author.name}
                          loading="lazy"
                        />
                      </div>
                    </author>
                    <div className="blog-detail__info">
                      <ul className={`blog-detail__directory blog-detail__directory--author directory`}>
                        <li>
                          <dt className="directory__title"  style={{ color: websiteFontColor }} ><FormattedMessage id="basic.author" defaultMessage="Autor"/></dt>
                          <dd className="directory__value">{author && author.name}</dd>
                        </li>
                        {categoryLink && (
                          <li className="d-none d-md-block">
                            <dt className="directory__title"  style={{color: websiteFontColor }} ><FormattedMessage id="basic.category" defaultMessage="Category"/></dt>
                            <dd className="directory__value">
                              <Link to={`/${language}/${categoryLink && categoryLink.path}`}>
                                {categoryLink && categoryLink.label}
                              </Link>
                            </dd>
                          </li>
                        )}
                        <li className={categoryLink ? "d-none d-md-block" : ""}>
                          <dt className="directory__title" style={{color: websiteFontColor }} ><FormattedMessage id="basic.date" defaultMessage="Datum"/></dt>
                          <dd className="directory__value">
                            {date && date
                              .split("-")
                              .reverse()
                              .join(".")}
                          </dd>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {categoryLink && (
                    <ul className="blog-detail__directory blog-detail__directory--details directory d-md-none">
                      <li>
                        <dt className="directory__title"  style={{color: websiteFontColor }} ><FormattedMessage id="basic.category" defaultMessage="Category"/></dt>
                        <dd className="directory__value">
                          <Link to={`/${language}/${categoryLink && categoryLink.path}`}>
                            {categoryLink && categoryLink.label}
                          </Link>
                        </dd>
                      </li>
                      <li>
                        <dt className="directory__title" style={{color: websiteFontColor }} ><FormattedMessage id="basic.date" defaultMessage="Datum"/></dt>
                        <dd className="directory__value">
                          {date && date
                            .split("-")
                            .reverse()
                            .join(".")}
                        </dd>
                      </li>
                    </ul>
                  )}
                </div>
                <div className="col-md-7">
                  <div className="blog-detail__lead">{intro}</div>
                </div>
              </div>
            </div>
            {main.map((content, index) => {
              if (content.__typename === "STRAPI_ComponentPlainRichTextBlock" && content.richText) {
                return (
                  <div  className={classNames("blog-detail__body", {"anchor-text": stripString(content.anchorText && content.anchorText ||'') })} id={stripString(content.anchorText && content.anchorText ||'')}>
                    <div className="container">
                      <div className="row">
                        <div key={index} className="col-md-6 offset-md-5 rich-text">
                          <Markdown options={{overrides: {a: {component: Link}, 
                            hr: { 
                              component: CustomHr,
                              props: {
                                borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                              }
                            }                       
                           }}}>
                            {formatRichText(content.richText)}
                          </Markdown>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              } else if (content.__typename === "STRAPI_ComponentPlainPaddedRichText" && content.richText) {
                return (
                  <div  
                    className={classNames("blog-detail__body blog-detail__body--padded", {"anchor-text": stripString(content.anchorText && content.anchorText ||'') })} 
                    id={stripString(content.anchorText && content.anchorText ||'')}
                  >
                    <div className="container">
                      <div className="row">
                        <div key={index} className="col-md-6 offset-md-5 rich-text">
                          <Markdown 
                            options={{
                              overrides: {
                                a: {component: Link}, 
                                div: {
                                  component: Div,
                                  props: {
                                    border: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                                  }
                                }, 
                                hr: { 
                                  component: CustomHr,
                                  props: {
                                    borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                                  }
                                },  
                                code: {
                                  component: Code, 
                                  border: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                                }
                              }
                            }
                            }
                          >
                            {formatRichText(content.richText)}
                          </Markdown>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              } else if (content.__typename === "STRAPI_ComponentPlainMedia") {

                return (
                  <div className="blog-detail__image blog-detail--fix">
                    <Media 
                      media={content.media} 
                      width={'semiMedium' ||content.width} 
                      withCaption={true} 
                      imageBorderRadiusForServices={imageBorderRadiusForServices}
                    />
                  </div>
                )
              }
            })}
          </div>
        </article>
       {(articles && filteredRelatedCards.length) && (
        <section className="section blog-related cards-articles" style={{backgroundColor: sectionBackgroundColor}}>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <header className="blog-related__header header">
                  <h1 className="header__heading header__heading--space">
                    {/* <small className="header__preheading">{articles && articles.sup}</small> */}
                    {articles && articles.heading}
                  </h1>
                </header>
              </div>
            </div>
            <div className="row card-articles">
              {articles && filteredRelatedCards.map((relatedCard) => {
                const article = relatedCard.blog || relatedCard.case_study;

                return ( 
                  <CardArticle 
                    article={article} 
                    path={relatedCard.blog ? 'blog' : 'case-studies'} 
                    imageBorderRadiusForServices={imageBorderRadiusForServices}
                    hashtagsBackgroundColor={displayedHashtagBgColor}
                  />
                )
              })}
            </div>
          </div>
        </section>
       )}

      </SiteHeaderAlt>
    </Layout>
  )
}

export default BlogDetail

export const pageQuery = graphql`
  query BlogDetail($id: ID!, $language: String!) {
    allSitePage {
      nodes {
        path
      }
    }
    strapi {
      general {
        primaryBtn
        blogsOverviewHeadline
        imageBorderRadiusForServices
        greySectionLightModeBgColor
        greySectionDarkModeBgColor
        generalBackgroundColorDarkMode
        generalBackgroundColorLightMode
        fontColorDarkMode
        fontColorLightMode
        hashtagsBackgroundColor
        hashtagsBackgroundColorDarkMode
        clientLogosColorDarkMode 
        clientLogosColorLightMode
      }
      blog(id: $id) {
        copyright
        darkTheme
        tags {
          active
          tag
        }
        webpages {
          about_us {
            language
          }
          contact {
            language
          }
          case_study {
            slug
            language
          }
          blogs_overview {
            language
          }
          cases_overview {
            language
          }
          careers_overview {
            language
          }	
          services_overview {
            language
          }
          blog {
            language
            slug
          }
          career {
            language
            slug
          }
          homepage {
            slug
            language
          }
          service_design {
            slug
            language
          }
          service_technical {
            slug
            language
          }
          service_casey_new {
            slug
            language
          }
          service_casey {
            slug
            language
          }
          plainpage {
            language
            slug
          }
        }
        seo {
          metaData {
            metaDescription
            metaTitle
          }
        }
        language
        title
        headerImage {
          url
        }
        authorHeading
        author {
          name
          picture {
            url
          }
        }
        category
        categoryLink {
          label
          path
        }
        date
        dateLabel
        intro
        main {
          ... on STRAPI_ComponentPlainRichTextBlock {
            __typename
            richText
            anchorText
          }
          ... on STRAPI_ComponentPlainPaddedRichText {
            __typename
            richText
            anchorText
          }
          ... on STRAPI_ComponentPlainMedia {
            __typename
            media {
              url
              ext
              alternativeText
              caption
              mime
            }
            width
          }
        }
        articles {
          sup
          heading
          relatedCard {
            blog {
              ...CardArticleBlog
            }
            case_study {
              ...CardArticleCaseStudy
            }
          }
        }
      }
      headers(where: {language: $language}) {
        ...SiteHeader
      }
      footers(where: { language: $language }) {
        ...SiteFooter
      }
    }
  }
`
